import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    width: 300,
    maxHeight: 250,
    '& h2': {
      fontSize: 18,
    }
  },
});

export default function SimpleLocationCard(props) {
  const classes = useStyles();

  const { item, setPopup } = props;

  function handleCardClick(e) {
    setPopup([e, 'location', item]);
  }

  return (
    <Card className={classes.root} onClick={() => handleCardClick(item.id)}>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" component="h2">
            {item.name}
            {item.character}
          </Typography>
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="Type" secondary={item.type} />
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>

    </Card>
  );
}
