import React, { useState, useEffect } from "react";
import "./App.css";
import { useCharacters, useLocations } from "./api/useData";
import SimpleLocationCard from "./components/SimpleLocationCard";
import SimpleCharacterCard from "./components/SimpleCharacterCard";
import Popup from "./components/Popup";
import Pagination from '@material-ui/lab/Pagination';

function App() {
  const [charPage, setCharPage] = useState(1);
  const [locPage, setLocPage] = useState(1);

  const [page, setPage] = useState('default');

  const [popup, setPopup] = useState(0);

  let characters = useCharacters(charPage);
  let locations = useLocations(locPage);

  console.log(characters)
  console.log(locations)
  // oldalváltás
  function handleClickPage(value) {
    setPage(value);
  }

  // karakter lapozás 
  function handleClickCharPage(e) {
    setCharPage(parseInt(e.target.innerText));
  }

  // lokáció lapozás 
  function handleClickLocPage(e) {
    setLocPage(parseInt(e.target.innerText));
  }

  return (<>
    <div className="App">
      <img className="logo" src="https://help.redbubble.com/hc/article_attachments/360002309526/Rick_and_Morty_-_logo__English_.png" alt="logo"></img>
      <div className="btn-container">
        <button onClick={() => handleClickPage('characters')} className="character-button">Characters</button>
        <button onClick={() => handleClickPage('locations')} className="location-button">Locations</button>
      </div>
      {page === 'default' &&
        <p className="landing-desc">
          Hi! This is a React app for the famous series Rick and Morty. You can check
        the characters and their description, if you click on the <em> Characters </em>
        button. You can check the locations from the series, if you click on the
        <em> Locations</em> button.
        </p>
      }
      {page === 'characters' &&
        <div className="charactersContainer">
          {characters.results.map((item) => <SimpleCharacterCard setPopup={setPopup} item={item} />)}
          <div className="hr"></div>
          <Pagination
            className="charPagination"
            count={34}
            variant="outlined"
            shape="rounded"
            onChange={handleClickCharPage}
          />
        </div>
      }
      {page === 'locations' &&
        <div className="locationsContainer">
          {locations.results.map((item) => <SimpleLocationCard setPopup={setPopup} item={item} />)}
          <div className="hr"></div>
          <Pagination
            className="locPagination"
            count={6}
            variant="outlined"
            shape="rounded"
            onChange={handleClickLocPage}
          />
        </div>
      }
    </div>
    {popup && <Popup pop={popup} setPopup={setPopup} />}
  </>

  );
}
export default App;
