import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CancelButton from './CancelButton';

const useStyles = makeStyles({
    root: {
        borderRadius: 20,
        maxWidth: 900,
        position: 'relative',
    },
});

export default function DetailedCharacterCard(props) {
    const classes = useStyles();
    const { item, setPopup } = props;

    function handleCancel() {
        setPopup(0);
    }

    return (
        <Card className={classes.root}>
            <CancelButton className="cancel" onClick={handleCancel}>X</CancelButton>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt=""
                    height=""
                    image={item.image}
                    title=""
                />
                <CardContent>
                    <Typography variant="h5" component="h2">
                        {item.name}

                    </Typography>
                    <List className={classes.root}>
                        <ListItem>
                            <ListItemText primary="Species" secondary={item.species} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Gender" secondary={item.gender} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Status" secondary={item.status} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Origin" secondary={item.origin.name} />
                        </ListItem>
                    </List>
                </CardContent>
            </CardActionArea>

        </Card>
    );
}