import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';
import CancelButton from './CancelButton';


const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    width: 300,
    height: 250,
    position: 'relative',
    '& h2': {
      fontSize: 20,
    }
  },
});

export default function DatailedLocationCard(props) {
  const classes = useStyles(props);
  const { setPopup, item } = props;

  function handleCancel() {
    setPopup(0);
  }

  return (
    <Card className={classes.root}>
      <CancelButton className="cancel" onClick={handleCancel}>X</CancelButton>
      <CardActionArea>
        <CardContent>
          <Typography variant="h5" component="h2">
            {item.name}
            {item.character}
          </Typography>
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="Type" secondary={item.type} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Dimension" secondary={item.dimension} />
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>

    </Card>
  );
}
