import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  root: {
    borderRadius: 20,
    maxWidth: 300,
    maxHeight: 450,
    '& h2': {
      fontSize: 20,
    }
  },

});

export default function SimpleCharacterCard(props) {
  const classes = useStyles();

  const { item, setPopup } = props;

  function handleCardClick(e) {
    setPopup([e, 'character', item]);
  }
  return (
    <Card className={classes.root} onClick={() => handleCardClick(item.id)}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt=""
          height=""
          image={item.image}
          title=""
        />
        <CardContent>
          <Typography variant="h5" component="h2">
            {item.name}

          </Typography>
          <List className={classes.root}>
            <ListItem>
              <ListItemText primary="Species" secondary={item.species} />
            </ListItem>
          </List>
        </CardContent>
      </CardActionArea>

    </Card>
  );
}
