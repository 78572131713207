import DetailedCharacterCard from "./DetailedCharacterCard";
import DetailedLocationCard from "./DetailedLocationCard";
import React from 'react';
import "./Popup.css";

export default function Popup(props) {
  const { pop, setPopup } = props;
  console.log(pop)
  return <div className="overlay">
    {
      pop[1] === "character" ?
        <DetailedCharacterCard item={pop[2]} setPopup={setPopup} /> :
        <DetailedLocationCard item={pop[2]} setPopup={setPopup} />
    }
  </div>;
}